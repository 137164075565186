





























import { Component, Vue } from 'vue-property-decorator'
import { getPlatform } from '@/utils/verify'

@Component({
  name: 'Download'
})
export default class Download extends Vue {
  // 是否隐藏模态框
  isShow = false

  // 立即下载
  handelDownload() {
    const pla = getPlatform()
    this.isShow = true
    if (pla === 'weixin' || pla === 'qq') {
      this.isShow = true
    } else {
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=1'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_dianshi
      }, 500)
      this.isShow = false
    }
  }
}
